.about {
    background-color: #222;
    color: #fff;
    padding: 50px;
    text-align: center;
  }
  
  .about h2 {
    color: #00bfff;
  }
  