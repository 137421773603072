header {
  background-color: #000000b0;
  padding: 20px;
  position: fixed;
  width: 90%;
  border-radius: 20px;
  z-index: 1000;
  overflow-x: hidden;
  top: 20px; /* Position it from the top if you'd like */
  left: 50%; /* Position from the left at halfway of the viewport */
  transform: translateX(-50%); /* Shift it back by half its own width to center it */
}

header .container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 50px; /* Adjust as needed */
}

nav {
  flex-grow: 1;
}

.nav-links {
  display: flex;
  justify-content: space-evenly; /* Spreads the items evenly */
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
}

.nav-links li a:hover {
  color: #4043ff;
}

@media (max-width: 768px) {
  /* Responsive adjustments */
  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin-bottom: 10px;
  }
}
