footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .footer-cta {
    margin-bottom: 20px;
  }
  
  .footer-cta h3 {
    margin-bottom: 10px;
  }
  
  .footer-cta button {
    padding: 10px 20px;
    background-color: #00bfff;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .footer-cta button:hover {
    background-color: #009acd;
  }