/* General Styles */
.home {
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: #fff;
  background: rgba(0, 0, 0, 0.418); /* Remove the solid black background */
}

#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Keep it at 0 so it's not behind the .home background */
}

/* Hero Content */
.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  z-index: 1; /* Ensures content appears above the video */
}

.hero h2 {
  font-size: 3.6em;
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
}

.hero p {
  max-width: 600px;
  margin: 20px auto;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero button {
  margin-top: 30px;
  padding: 15px 30px;
  background-color: #00bfff;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
}

.hero button:hover {
  background-color: #009acd;
}
  
  /* Features Section */
  .features {
    background-color: #111;
  }
  
  .feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .feature-item {
    flex: 1 1 300px;
    margin: 20px;
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
  }
  
  .feature-item h4 {
    margin-bottom: 15px;
  }
  
  /* Testimonials Section */
  .testimonials {
    background-color: #333;
  }
  
  .testimonial-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial-item {
    max-width: 600px;
    margin: 20px;
    background-color: #222;
    padding: 30px;
    border-radius: 10px;
  }
  
  .testimonial-item p {
    font-style: italic;
  }
  
  .testimonial-item h5 {
    margin-top: 15px;
    font-weight: normal;
  }
  
  /* How It Works Section */
  .how-it-works {
    background-color: #111;
  }
  
  .how-it-works ol {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }
  
  .how-it-works li {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  /* Call to Action Section */
  .cta {
    background-color: #000;
  }
  
  .cta button {
    padding: 15px 30px;
    background-color: #00bfff;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .cta button:hover {
    background-color: #009acd;
  }
  
  /* CSS Animations */
  .reveal {
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .reveal.active {
    opacity: 1;
    transform: translateY(0);
  }
  
  @media (max-width: 768px) {
    .feature-list {
      flex-direction: column;
    }
  
    .hero h2 {
      font-size: 2em;
    }
  
    .hero button,
    .cta button {
      width: 80%;
      max-width: 300px;
    }
  }