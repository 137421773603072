.contact {
    background-color: #111;
    color: #fff;
    padding: 50px;
    text-align: center;
  }
  
  .contact h2 {
    color: #00bfff;
    margin-bottom: 30px;
  }
  
  .contact form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact input,
  .contact textarea {
    width: 80%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #222;
    border: 1px solid #444;
    color: #fff;
    border-radius: 5px;
  }
  
  .contact button {
    padding: 10px 20px;
    background-color: #00bfff;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .contact button:hover {
    background-color: #009acd;
  }
  